<template>
  <div class='box w628 h214 pt20 fs16'>
    <div class="text-center">—— 近一周机器人使用记录 ——</div>
    <!-- 表头 -->
    <div class="tabListHeader  pr27 flex col-center">
      <div class="tabListHeader_after"></div>
      <div class="w97 text-right">设备名称</div>
      <div class="w180 text-center">开始运行时间</div>
      <div class="w194 text-center">结束运行时间</div>
      <div class="w86 text-center">消毒时长</div>
    </div>
    <!-- //表头 -->
    <div class="h105" style="overflow: hidden;">
      <div class="roll_box" :style="{'--updateStyle':speend+'s'}" v-if="list.length>0">
        <div class="flex pr27 h36  table_item lh36" v-for="(item,i) in list" :key="i">
          <div class="w97 text-center name_box">{{item.name}}</div>
          <div class="w180 text-center">{{item.openTime}}</div>
          <div class="w194 text-center">{{item.endTime}}</div>
          <div class="w86 text-center">{{item.hours}}h</div>
        </div>
      </div>
      <div class="text-center lh105" v-else>暂无数据</div>
    </div>

  </div>
</template>

<script>
import { getWeekUser } from '@/api/Robot'
import clearTime from '@/mixin/clearTime.js'
import { format } from '@/utils/pubilcjs'
export default {
  name: "shebeiUseList",
  mixins: [clearTime],
  data () {
    return {
      list: [],
      speend: null
    };
  },

  created () {
    this.getWeekUser()
    this.timer = setInterval(() => {
      this.getWeekUser()
    }, 180000)
  },
  // fifter: {

  // },
  computed () {

  },

  mounted () { },

  methods: {
    async getWeekUser () {
      const { data } = await getWeekUser()
      console.log(111, data);
      data.forEach(item => {
        item.hours = (item.workingTime / 3600).toFixed(1)
        item.openTime = format(item.startTime / 180000)
        item.endTime = format(item.finishTime / 180000)
      })
      if (data.length >= 3) {
        this.list = [...data, ...data]
        this.speend = this.list.length
      } else {
        this.list = data
        this.speend = null
      }
      // data.forEach(item => {
      //   if (item.onOff == 1) {
      //     item.openTime = item.sendTime,
      //       item.endTime = '- -'
      //   } else {
      //     item.openTime = '- -',
      //       item.endTime = item.sendTime
      //   }
      //   item.workingHours = this.formatterWorkingHours(item.workingHours)
      // })

    },
    //时间过滤器
    formatterWorkingHours (val) {
      // return parseInt(row.workingHours / 3600) + 'h'
      var secondTime = parseInt(val); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      // var result = "" + parseInt(secondTime) + "秒";
      var result
      if (minuteTime >= 0) {
        result = "" + parseInt(minuteTime) + "分";
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
      }
      return result;
    }
  }
}

</script>
<style lang='scss' scoped>
.box {
  background: url("~@/assets/img/4.0/centerBottom.png") no-repeat;
  background-size: 100% 100%;
}
// .table_item:nth-child(even) {
//   background: linear-gradient(
//     90deg,
//     rgba(19, 250, 239, 0.24) 0%,
//     rgba(8, 244, 218, 0) 100%
//   );
//   border-radius: 5px;
// }
.name_box {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.roll_box {
  animation: move var(--updateStyle) linear infinite;
}
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
.roll_box:hover {
  animation-play-state: paused;
}
</style>