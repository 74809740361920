<template>
  <el-carousel indicator-position='none' arrow='never'>
    <el-carousel-item v-for="(item,i) in list" :key="i">
      <div class='box flex col-center'>
        <div class="w75 h120 shebei_name pl22 pr20 pt10 pb10">{{item.name}}</div>
        <div class="detail w486 h210 ml10  pt28 flex  row-around">
          <div class="flex row-column col-center pt3">
            <div class="fs24 fm-f mb8" style="color:#FFC53B">
              {{item.state}}
            </div>
            <img src="@/assets/img/4.0/robotState/wind.png" class="w114 h150">
            <div class="fs16 speend">运行状态</div>
          </div>
          <div class="flex row-column col-center">
            <div class="flex col-center" style="color:#FFC53B">
              <div class="fs48 fm-n mr4">{{item.parameter8}}</div>
              <div class="fs17">%</div>
            </div>
            <img src="@/assets/img/4.0/robotState/wind.png" class="w114 h150">
            <div class="fs16 speend">剩余电量</div>
          </div>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'robotState',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  mixins: [clearTime],
  data () {
    return {
    };
  },

  created () {
  },

  mounted () { },

  methods: {
  }
}

</script>
<style lang='scss' scoped>
.shebei_name {
  text-align: center;
  font-size: 20px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #ffffff;
  writing-mode: vertical-lr;
  text-orientation: upright;
  background: url("~@/assets/img/4.0/robotState/shebeiName.png") no-repeat;
  background-size: 100% 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail {
  background: url("~@/assets/img/4.0/robotState/detail_border.png") no-repeat;
  background-size: 100% 100%;
  img {
    margin-top: -30px;
  }
  .speend {
    margin-top: -35px;
  }
}
</style>